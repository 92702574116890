.container {
  width: 850px;
  height: 500px;
  display: flex;
  flex-direction: column;
  // padding: 10px;

  .topbar {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px 0 5px 0;
    border-bottom: solid 1px gray;


    .item {
      display: flex;
      align-items: center;
      width: 200px;
      margin-right: 10px;

      // background-color: aqua;
      .title {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 2px;
      }

      .control {
        flex: 1;
      }
    }
  }

  .bottom {
    width: 100%;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 5px;
    overflow-y: auto;
    // background-color: aquamarine;

    .item {
      width: 200px;
      height: 200px;
      // background-color: #a0a0a0;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 10px;
      border: solid 1px;
    }

    .add {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 80px;
      font-weight: bold;
      width: 250px;
      height: 190px;
      margin: 10px;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;
      background-color: white;
    }

    .card {
      width: 250px;
      height: 190px;
      margin: 10px;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;
      // padding: 10px;

      .icon {
        cursor: pointer;
      }

      .meta{
        height: 98px;
      }
  
      .content {
        text-overflow: ellipsis; //超出用。。。代替
        overflow: hidden; //超出不显示
        white-space: nowrap; //不换行
      }

      .top {
        margin: 0 0 10px 0;
      }
    }
  }
}

.datavcontent {
  // position: relative;
  overflow: hidden;
  transform-origin: top left;
  background-position: 50% 50%;
  background-repeat: repeat;
  background-size: cover;
  background-color: #303030;
}