.container {
  width: 100%;
  height: 100vh;
  text-overflow: ellipsis;
  // overflow-x: hidden;

  :global(.ant-carousel .slick-dots) {
    margin-left: 0;
    margin-right: 0;
  }
}


.datavcontent {
  // position: relative;
  // position: absolute;
  overflow: hidden;
  transform-origin: top left;
  background-position: 50% 50%;
  background-repeat: repeat;
  background-size: cover;
  // background-color: #404040;

  // transform-origin: top left;
  //   transform: scale(0.5);
  //   background-position: 50% 50%;
  //   background-repeat: repeat;
  //   background-size: cover;
  //   z-index: 20;
  //   border: solid 1px red;
  // background-image: url("https://datav.oss-cn-hangzhou.aliyuncs.com/uploads/images/a5aee7816b79b75bd752c12cfb0d3150.png");
}