.input {
  width: calc(100% - 32px);
}

.tag {
  width: 100%;
  height: 34px;
  padding: 5px 0;
  display: flex;
  justify-items: center;
  text-overflow: ellipsis; //超出用。。。代替
  overflow: hidden; //超出不显示
  white-space: nowrap; //不换行
  border: solid 1px #d9d9d9;
}