.datavcontent {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  // overflow-y: scroll;
  background-image: url('https://images.muzhiyun.cn/muzhicloud/9208750090/user/2023-10-20/138317fa-91f3-e101-bee9-0f18bd6f79dd.png');
  background-position: 50% 50%;
  background-repeat: repeat;
  // border: solid 1px red;
  overflow: hidden;

  .configcontent {
    position: absolute;
    // left: 50px;
    // top: 50px;
    transform-origin: top left;
    transform: scale(0.5);
    background-position: 50% 50%;
    background-repeat: repeat;
    background-size: cover;
    z-index: 20;
    border: solid 1px red;
  }

  .thumbnail {
    position: absolute;
    background-color: #001529;
    z-index: 30;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    .thumbnailcontent {
      width: 100%;
      height: 100%;
      background-color: antiquewhite;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .movescreen {
      position: absolute;
      cursor: move;
      //e-resize 左右移动 n-resize 上下移动
      // cursor: ne-resize;
      left: 0;
      top: 0;
      border: solid 1px aqua;
      background-color: transparent;
    }
  }

}

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  width: 100%;
  padding: 0 0 10px 0;
  border-bottom: solid 1px;

  .select {
    width: 300px;
    margin-right: 10px;
  }

  .btn {
    margin: 1px;
  }
}

.tree {
  overflow-y: auto;
  max-height: calc(100% - 55px);
}

.node {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    margin-right: 15px;
  }

  .icon {
    margin-left: 10px;
    cursor: pointer;
  }
}

.popovercontent {
  width: 600px;
  height: 400px;

  .topbar {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
  }
}


// 这里Select组件的popupClassName不知为什么必须放在最外层才生效
.dropdown {
  background-color: #001529;
}