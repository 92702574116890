.slider {

  // 默认的上移下移按钮在白色背景下看不见，这里改成灰色按钮
  :global {
    // .slick-next:before {
    //   color: gray;
    // }

    // .slick-prev:before {
    //   color: gray;
    // }

    .slick-next {
      right: 5px;
      z-index: 1;
    }

    .slick-prev {
      left: 5px;
      z-index: 1;
    }

    .slick-dots {
      bottom: 5px;
    }
  }
}