// $baselength: 100px;

.cube {
  position: relative;
  transform-style: preserve-3d;
  // transform: translateZ(0.5*$baselength) rotateX(-15deg) rotateY(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  // animation: rotateself 20s linear infinite;

  // @keyframes rotateself {
  //   0% {
  //     transform:  rotateX(-15deg) rotateY(0) translateZ(0.5*$baselength);
  //   }

  //   100% {
  //     transform:  rotateX(-15deg) rotateY(360deg) translateZ(0.5*$baselength);
  //   }
  // }

  .top {
    position: absolute;
    border: 1px solid black;
    transform-style: preserve-3d;
    // transform: translateY(-50%) translateZ(-0.5*$baselength) rotateX(90deg);
    display: flex;
    flex-wrap: wrap;
    background-color: yellow;
  }

  .front {
    position: absolute;
    border: 1px solid black;
    transform-style: preserve-3d;
    display: flex;
    flex-wrap: wrap;
    background-color: blue;
  }

  .right {
    position: absolute;
    border: 1px solid black;
    transform-style: preserve-3d;
    // transform: translateX(0.5*$baselength) translateZ(-0.5*$baselength) rotateY(90deg);
    display: flex;
    flex-wrap: wrap;
    background-color: red;
  }

  .back {
    position: absolute;
    // width: $baselength;
    // height: $baselength;
    border: 1px solid black;
    transform-style: preserve-3d;
    // transform:  translateZ(-1*$baselength) rotateY(-180deg);
    display: flex;
    flex-wrap: wrap;
    background-color: green;
  }

  .left {
    position: absolute;
    // width: $baselength;
    // height: $baselength;
    border: 1px solid black;
    transform-style: preserve-3d;
    //transform: translateX(-0.5*$baselength) translateZ(-0.5*$baselength) rotateY(-90deg);
    display: flex;
    flex-wrap: wrap;
    background-color: orange;
  }

  .bottom {
    position: absolute;
    // width: $baselength;
    // height: $baselength;
    border: 1px solid black;
    transform-style: preserve-3d;
    // transform: translateY(0.5*$baselength) translateZ(-0.5*$baselength)  rotateX(90deg);
    display: flex;
    flex-wrap: wrap;
    background-color: white;
  }

  .grid {
    width: 33.333%;
    height: 33.333%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}