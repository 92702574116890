.container {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #f0f2f5;
  

  .title {
    background-color: white;
    // margin: 5px;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
  }

  .tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    margin: 5px 0;
    padding: 10px;

    .btn {
      margin-right: 30px;
    }
  }

  .content {
    background-color: white;
    // max-height: 500px;
    // overflow-y: auto;
  }
}

