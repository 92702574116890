.container{
  .topbar{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 5px;
    overflow-x: auto;
    background-color: #efebef;
    
    .card{
      width: 300px;
      height: 200px;
      margin: 0 5px 0 0;
      // border: solid 1px;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;
      .icon{
        cursor: pointer;
      }
    }
    .add{
      width: 120px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 60px;
      background-color: white;
      cursor: pointer;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;
    }
  }

  .pagegroup{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;
  }

  .content{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 5px 5px 5px;
    background-color: white;
    height: auto;

    .card{
      width: 160px;
      height: 197px;
      margin: 0 10px 10px 0;
      // border: solid 1px;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;
      .icon{
        cursor: pointer;
      }
    }

    .add{
      width: 160px;
      height: 197px;
      margin: 0 10px 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 60px;
      background-color: white;
      cursor: pointer;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;
    }
  }
}