.container {
  display: flex;;
  flex-direction: row;
  width: 100%;
  height: 100vh;


  .left{
    flex: 1;
    height: 100%;
  }
  .center{
    width: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .right{
    flex: 1;
    height: 100%;
  }
}