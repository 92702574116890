.italic{
  font-style: italic;
}
.unitalic{
  font-style: normal;
}

.underline{
  text-decoration: underline;
}

.ununderline{
  text-decoration: none;
}