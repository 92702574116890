.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;

  .title {
    font-size: 35px;
    margin: 40px;
  }

  .detailtitle {
    font-size: 20px;
    margin: 20px;
  }
}

.modal {
  .line {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .title {
      width: 100px;
      text-align: right;
      padding-right: 5px;
    }
    .item{
      flex: 1;
    }
  }
}