.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .titleline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 30px;
    color: #f759ab;
  }

  .bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: #f759ab;

    .cubebar {
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: space-between;
      // background-color: red;     

      .left {
        position: relative;
        height: 100%;
        width: 50%;
        aspect-ratio: 1;
        // background-color: green;
        // background-color: aqua;
      }

      .right {
        width: 50%;
        height: 100%;
        // background-color: yellow;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .info {
          margin: 5px 0 0 0;
          display: flex;
          align-items: center;
        }

        .plantcube {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            font-size: 18px;
            font-weight: bold;
            margin: 5px;
          }

          .plantcubecontiner {
            flex: 1;
            aspect-ratio: 1;
            // background-color: aqua;
          }
        }

        .btntool {
          margin: 5px 0;
          display: flex;
          justify-content: space-around;

          .btn {
            margin: 0 10px;
          }
        }

      }

    }


    .plantcubebar {
      padding: 10px;
      flex: 1;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(3, 1fr);

      .pllitem {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        // background-color: aquamarine;
        padding: 5px;
        z-index: 9;

        .title {
          // margin-top: 5px;
          font-size: 18px;
          font-weight: bold;
        }

        .plantcube {
          width: 100%;
          aspect-ratio: 1;
          padding: 5px;
        }
      }
    }
  }
}

.modal {
  width: 100%;
  max-height: 500px;
}