.topbar{
  height: 64px;
  background-color: #222125;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 50px;

  .logo {
    height: 33px;
    pointer-events: none;
    // padding-left: 20px;
  }



  .topcenter{
    flex: 1;
  }
}