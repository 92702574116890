

.text {
  position: relative;
  color: #000;
  text-decoration: none;
  margin: 2px;
  cursor: pointer;
  font-weight: bold;
  color: #102c6b;
}

.text:hover{
  color: #ff8600;
  text-decoration:underline;
}

.image{
  position: relative;
  cursor: pointer;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  display: block;
  outline: none;
  margin: 2px;
}

.moretitle{
  display: flex;
  flex-direction: row-reverse;
  padding: 2px 5px 2px 0;  
}