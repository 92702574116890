.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 16px;
  
  .main {
    flex: 1;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    // background-color: #ff8600;

    .text {
      position: relative;
      color: #000;
      text-decoration: none;
      padding: 6px 0;
      cursor: pointer;
      font-weight: bold;
      color: #102c6b;
      border-bottom: solid 1px #aaaaaa;
      // background-color: #ff8600;
    }

    .text:hover {
      color: #ff8600;
      text-decoration: underline;
    }

    .image {
      position: relative;
      cursor: pointer;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      overflow: hidden;
      display: block;
      outline: none;
      margin: 2px;
    }
  }

  .moretitle {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 5px 2px 0;
  }
}