.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  .grid {
    width: 25%;
    height: 25%;
    background-color: yellow;
    border: solid 1px black;
  }

  .blank {
    width: 12.5%;
    height: 12.5%;
  }

  .horgrid {
    width: 25%;
    height: 12.5%;
    border: solid 1px black;
  }

  .vergrid {
    width: 12.5%;
    height: 25%;
    border: solid 1px black;
  }
}