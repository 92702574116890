.container {
  text-overflow: ellipsis;
  // overflow-x: hidden;

  :global(.ant-carousel .slick-dots) {
    margin-left: 0;
    margin-right: 0;
  }
  
  .pageheader {
    position: relative;
    z-index: 99999;
    background-color: #001529;
    padding: 0 20px;

    .topbar {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 50px;
      z-index: 999;

      .pagename {
        padding-left: 20px;
        color: white;
        font-size: 20px;
        cursor: pointer;
      }

      .topcenter {
        flex: 1;
        display: flex;
        align-items: center;

        .scaletool {
          display: flex;
          align-items: center;
          padding-left: 50px;
          color: white;

          .customselect {
            width: 120px;
            margin-right: 10px;

            :global {
              .ant-select-selector {
                background-color: #001529;
                color: white;
                border: solid 1px gray;
              }
            }
          }

          .slider {
            width: 200px;
          }

          .btn {
            cursor: pointer;
            font-size: 16px;
          }
        }
      }

      .btn {
        margin: 0 5px;
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }

  .pccontent {
    position: relative;
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: scroll; //这个属性会影响 Parallax 组件
  }

  .phonecontent {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    width: 100%;
    height: calc(100vh - 64px);
    // overflow-y: scroll;
    font-size: 16px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: solid 1px red;
    // background-image: url('phone.jpeg');

    .content {
      // margin: 0 0 0 2px;
      width: 310px;
      height: 560px;
      background-color: white;
      overflow-y: scroll;
      scrollbar-width: none; //Firefox 隐藏滚动条
    }

    .content::-webkit-scrollbar {
      display: none; //针对Chrome, Safari 和 Opera 隐藏滚动条
    }
  }

  // .datavcontent {
  //   position: relative;
  //   width: 100%;
  //   height: calc(100vh - 64px);
  //   // overflow-y: scroll;
  //   background-image: url('https://images.muzhiyun.cn/muzhicloud/9208750090/user/2023-10-20/138317fa-91f3-e101-bee9-0f18bd6f79dd.png');
  //   background-position: 50% 50%;
  //   background-repeat: repeat;
  //   // border: solid 1px red;
  //   overflow: hidden;

  //   .configcontent {
  //     position: absolute;
  //     // left: 50px;
  //     // top: 50px;
  //     transform-origin: top left;
  //     transform: scale(0.5);
  //     background-position: 50% 50%;
  //     background-repeat: repeat;
  //     background-size: cover;
  //     z-index: 20;
  //   }

  //   .thumbnail {
  //     position: absolute;
  //     background-color: #001529;
  //     z-index: 30;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 5px;

  //     .thumbnailcontent{
  //       width: 100%;
  //       height: 100%;
  //       background-color: antiquewhite;
  //     }
  //     .movescreen{
  //       position: absolute;
  //       cursor: move;
  //       // width: 50px;
  //       // height: 50px;
  //       left: 0;
  //       top:0;
  //       border: solid 1px aqua;
  //       background-color: transparent;
  //     }
  //   }
  // }
}

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  width: 100%;
  padding: 0 0 10px 0;
  border-bottom: solid 1px;

  .select {
    width: 300px;
    margin-right: 10px;
  }

  .btn {
    margin: 1px;
  }
}

.tree {
  overflow-y: auto;
  max-height: calc(100% - 55px);
  min-height: 500px;
}

.node {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    margin-right: 15px;
  }

  .icon {
    margin-left: 10px;
    cursor: pointer;
  }
}

.popovercontent {
  width: 600px;
  height: 400px;

  .topbar {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
  }
}

// 这里Select组件的popupClassName不知为什么必须放在最外层才生效
.dropdown {
  background-color: #001529;
}