.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;



  .top {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    .topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      font-size: 30px;

      .left {
        height: 100%;
        display: flex;
        align-items: center;

        .coin {
          height: 80%;
          width: auto;
          margin: 0 5px;
        }

        .card {
          height: 80%;
          width: auto;
          margin: 0 5px 0 15px;
        }
      }

      .info {
        font-size: 16px;
        margin-right: 15px;
      }
    }

    .titleline {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      color: #f759ab;
    }

    .centercontainer {
      flex: 1;
      width: 100%;
      display: flex;

      .leftcontainer {
        flex: 1;
        height: 100%;
        // padding: 10px;

        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(7, 1fr);

        .stair {
          border: solid 2px;
          // margin: 2px;
          background-color: #ff8c00;
        }

        .child {
          // height: auto;
          // height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .rightcontainer {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 25px;

        .line {
          display: flex;
          align-items: center;
          margin: 3px 0 3px 10px;

          .title {
            margin-right: 5px;
          }
        }

        .buttonbar {
          width: 100%;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px 0 0 0;
        }
      }
    }


  }

  .plantcubebar {
    padding: 10px;
    height: 60%;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .pllitem {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      // background-color: aquamarine;
      padding: 5px;
      z-index: 9;

      .title {
        // margin-top: 5px;
        font-size: 16px;
        font-weight: bold;
      }

      .plantcube {
        // width: 100%;
        aspect-ratio: 1;
        padding: 5px;
      }
    }
  }
}

.fire {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-11-05/fcbc000a-04f9-22c6-2e65-f9eeb06ee7af.gif);
}

.modal {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // align-content:
  padding: 20px;

  .pllitem {
    width: 33%;
    aspect-ratio: 1;
    padding: 30px;
    border-radius: 10px;
  }

  .selected {
    background-color: #f759ab;

  }
}