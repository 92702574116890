.container {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;

  .btn {
    margin-left: 5px;
  }

  .content {
    flex: 1;
    width: 100%;
    height: 100%;

    .table {
      height: 250px;
    }
  }
}

.contentbody {
  width: 100%;
  display: flex;
  flex-direction: row;

  .left {
    flex: 1;
    position: relative;
    border: solid 1px gray;

    .selectedrect {
      position: absolute;
      border: solid 1px red;
    }

    .rect {
      position: absolute;
      border: solid 1px green;
    }
  }

  .right {
    width: 250px;
    padding: 0 0 0 5px;

    .card {
      width: 100%;
      margin: 3px;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;
      font-weight: bold;
    }

    .icon {
      font-size: 20px;
    }

    .havecoords {
      color: #1890ff;
    }

    .selected {
      border: solid 1px #1890ff;
    }

    .add {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      width: 100%;
      height: 80px;
      margin: 3px;
      padding: 0 5px;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.scriptbody {
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    .item{
      display: flex;
      align-items: center;  
      .title{
        // width: 60px;
        white-space: nowrap;
        text-align: end;
        margin-left: 5px;
      }    
    }
    .btn{
      margin-left: 5px;
    }
  }

  .bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // height: 50px;
      margin: 3px;
      padding: 0 5px;
      box-shadow: 1px 1px 10px 0px gray;
      border-radius: 5px;

      .left {
        flex: 1;
        padding: 5px;
        .title {
          width: 100%;
          font-size: 16px;
          font-weight: bold;
        }
        .subtitle{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 20px;
        }
      }

      .icon {
        font-size: 20px;
        width: 30px;
        color: gray;
      }
    }

    .selected {
      border: solid 1px #1890ff;
    }
  }
}