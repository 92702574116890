.container{
  margin: 0 0 0 10px;
  // width: 100%;
  // height: 72px;
  // border-radius: 5px;
  // border: solid 1px ;
  // padding: 3px;


    .title{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding:0 2px 0 5px;
    }

    .iconbar{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // background-color: aqua;
      .icon{
        font-size: 20px;
        margin: 0 2px;
      }
      
    }
}