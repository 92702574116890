.video {
  background-color: gray;
  color: white;
  cursor: pointer;
}

.container {
  width: 100%;

  .top {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 10px;

    .imgitem {
      display: flex;
      align-items: center;
      width: 100%;
      border: solid 1px;
      padding: 5px;

      .img {
        width: 50px;
        height: 50px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        overflow: hidden;
        background-size: contain;
        background-color: #2f2f2f;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        cursor: pointer;
      }

      .title {
        padding-left: 5px;
        flex: 1;
        display: flex;
        align-items: center;
        text-overflow: ellipsis; //超出用。。。代替
        overflow: hidden; //超出不显示
        white-space: nowrap; //不换行
      }

      .icon {
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;

    .title {
      width: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 5px;
    }
  }
}