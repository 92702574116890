.italic{
  font-style: italic;
}
.unitalic{
  font-style: normal;
}

.underline{
  text-decoration: underline;
}

.ununderline{
  text-decoration: none;  
}

.hidescroll {
  // overflow-y: scroll;  
  // overflow: auto;
  scrollbar-width: none; //Firefox 隐藏滚动条
}

.hidescroll::-webkit-scrollbar {
  display: none;  //针对Chrome, Safari 和 Opera 隐藏滚动条
}