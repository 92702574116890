.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 1200px;

  .left {
    flex: 1;

    .img {
      border-radius: 5px;
      border: solid 1px #DDDDDD;
      margin: 5px;
      position: relative;

      .map {
        position: absolute;

        &:hover {
          border: solid 1px red;
          cursor: pointer;
        }
      }
    }
  }

  .center {
    width: 60px;
    // background-color: blue;
    border-radius: 5px;
    border: solid 1px #DDDDDD;
    margin: 5px;
    font-size: 14px;
    height: auto;

    .btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 60px;
      border-bottom: solid 1px #DDDDDD;
      cursor: pointer;

      .icon {
        font-size: 20px;
      }

      &:hover {
        color: brown;
      }
    }

    .btndisable {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 60px;
      border-bottom: solid 1px #DDDDDD;
      color: gray;
      cursor: no-drop;
    }
  }

  .right {
    flex: 1;
    // background-color: yellow;
    border-radius: 5px;
    border: solid 1px #DDDDDD;
    margin: 5px;
    // background-color: red;
    height: 100vh;
  }

  .menu {
    margin: 5px;
    border-radius: 5px;
    border: solid 1px #DDDDDD;

    .menutop {
      height: 40px;
      background-color: #ededed;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menutitle {
        color: brown;
        font-size: 16px;
        margin-left: 20px;
        // background-color: aqua;
      }

      .menubtn {
        text-align: right;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          color: brown;
        }
      }

      .menubtndisable {
        text-align: right;
        margin-right: 10px;
        color: gray;
        cursor: no-drop;
      }
    }

    .menubottom {
      .item {
        padding: 10px;
        cursor: pointer;
        border-bottom: dashed 1px gray;

        &:hover {
          color: brown;
        }
      }
    }
  }
}

.paperlist {
  padding: 0 10px 0 0;

  .paperitem {
    margin-bottom: 5px;
    margin-left: 10px;
    &:hover {
      cursor: pointer;
      color: brown;
    }
  }

  .selectpaperitem {
    margin-bottom: 5px;
    color: red;

    &:before{
      content: '';
      display: inline-block;
      width: 0px;
      height: 0px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent red;
    }
  }
}

.downloadlist {
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;

  .downloaditem {
    margin-bottom: 5px;
    margin-left: 10px;
    &:hover {
      cursor: pointer;
      color: brown;
    }
  }
}