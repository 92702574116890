.container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.italic{
  font-style: italic;
}
.unitalic{
  font-style: normal;
}

.underline{
  text-decoration: underline;
}

.ununderline{
  text-decoration: none;
}