.container {
  flex-grow: 1;
  width: 100%;
  /* overflow-y: auto; */
  background-color: #f1f1f1;
  padding-bottom: 30px;
  background-repeat: no-repeat;
  /* height: 745px; */
  // height: 100%;
  background-position-y: -240px;
  background-position-x: center;
  background-repeat: no-repeat;  

  .moduleItems {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    // animation: fadeInAnimation 3s ease 1 forwards;

    .groupItem {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      /* min-width: 300px; */
    }

    .card {
      width: 200px;
      min-width: 200px;
      height: 200px;
      min-height: 200px;
      border-radius: 10px;
      background-image: linear-gradient(#e9e8e8, rgba(255, 255, 255, 1));      
      transition-duration: 0.5s;
    }
    
    .card:hover {
      transform: perspective(100px) translate3d(0px, 0px, 15px);
      background-color: #ffffff;
      box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14);
      background-image: linear-gradient(#ffffff, #ffffff);
    }

    .item {
      width: 200px;
      min-width: 200px;
      height: 200px;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      position: relative;

      .icon {
        /* width: 100%;
          height: 100%; */
        object-fit: cover;
        margin-top: -20px;
      }
      
      .title {
        font-size: 18px;
        color: #000;
        margin-top: -50px;
      }
      
      .description {
        color: #a2a2a2;
        padding: 0px 20px;
        min-height: 39px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /*! autoprefixer: on */
        margin-top: -30px;
        font-size: 12px;
      }
    }
  }
}