.container {
  max-height: 600px;
  overflow-y: auto;
  .row {
    margin: 3px;
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 5px;

      .tip{
        margin-left: 3px;
        color: gray;
      }
    }

    .content {
      display: flex;
      align-items: center;
      padding: 0 5px;
      .warn{
        margin-left: 5px;
        color: red;
      }
    }
  }

  .debugcontent{
    display: flex;   
    .content{
      margin: 0 5px;
      flex: 1;
    } 
  }
}