.container {
  margin: 2px;
  width: 620px;
  // height: 72px;
  // border-radius: 5px;
  // border: solid 1px;
  padding: 3px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  .add {
    // width: 610px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 1px gray;
    cursor: pointer;
    border-radius: 5px;
    padding: 3px;
  }
}