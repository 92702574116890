.italic{
  font-style: italic;
}
.unitalic{
  font-style: normal;
}

.underline{
  text-decoration: underline;
}

.ununderline{
  text-decoration: none;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}

