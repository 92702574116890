.container{
  height: 64px;
  // background-color: #222125;
  display: flex;
  flex-direction: row;
  align-items: center;

  .logo{
    height: 33px;
    pointer-events: none;
    padding-left: 30px;
  }
  .center{
    flex: 1;
    margin-left: 20px;
  }

  .right{
    width: 200px;
    color: white;
    .nick{
      margin-right: 15px;
    }
  }
}
