.overlay {
  // :global(.ant-popover-inner-content) {
  //   padding: 0;
  // }

  :global {
    .ant-popover-inner-content {
      padding: 0px;
    }
  }
}


.ghost {
  background-color: rgba(255, 255, 255, 0);
  border: 0;
}

.ghost:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.primary {
  background-color: var(--backgroudcolor);
  border-color: var(--backgroudcolor);
}

.primary:hover {
  background-color: var(--hoverbackgroudcolor);
  border-color: var(--hoverbackgroudcolor);
}

.link {
  color: var(--color);
}

.link:hover {
  color: var(--hovercolor);
}


.italic{
  font-style: italic;
}
.unitalic{
  font-style: normal;
}

.underline{
  text-decoration: underline;
}

.ununderline{
  text-decoration: none;
}