.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  // height: 200px; /* 设置父容器的高度 */
  // width: 100px; /* 设置父容器的宽度 */




  .cube {
    width: 25%;
    aspect-ratio: 1;
    background-color: aquamarine;
  }

  .img {
    width: 200px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-11-05/6386ccc4-e82e-e8b5-a684-abb59eec0818.gif);
  }

  .fire {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-11-05/fcbc000a-04f9-22c6-2e65-f9eeb06ee7af.gif);
    // background-color: aqua;
  }
}